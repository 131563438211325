import { useEffect, useState } from 'react';
import {
  GetScionProperties,
  GetContactData,
  PostScionBookingData,
} from '../../api/helper';
import './styles/form.css';
const ScionForm = ({ params }) => {
  const [formStage, setFromStage] = useState(1);
  const [properties, setProperties] = useState([]);
  const [contactData, setContactData] = useState({});
  const [selectedProperty, setSelectedProperty] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      const propertyResponse = await GetScionProperties();
      const contactResponse = await GetContactData(params.crmId);
      setLoading(false);
      if (propertyResponse.status === 'success') {
        setProperties(propertyResponse.data);
      } else {
        setError(propertyResponse.data);
      }
      if (contactResponse.status === 'success') {
        setContactData(contactResponse.data);
      } else {
        setError(contactResponse.data);
      }
    }
    fetchData();
  }, [params]);
  const activeForm = {
    1: (
      <BookingForm
        crmId={params.crmId}
        properties={properties}
        setSelectedProperty={setSelectedProperty}
        setFromStage={setFromStage}
        selectedProperty={selectedProperty}
      />
    ),
    2: (
      <Preview
        selectedProperty={selectedProperty}
        contactData={contactData}
        setFromStage={setFromStage}
        setError={setError}
        crmId={params.crmId}
      />
    ),
    3: <Thankyou selectedProperty={selectedProperty} />,
  }[formStage];
  return (
    <>
      <div className='header'>
        <img
          alt='logo'
          className='logo'
          src='https://cdn.universityliving.com/logo/newLogo.png'
        />
      </div>
      <div className='form-parent'>
        <div className='main-form'>
          {loading ? (
            <p className='loading'>Loading....</p>
          ) : error ? (
            <p className='error'>Error: {error}</p>
          ) : (
            activeForm
          )}
        </div>
      </div>
    </>
  );
};
export default ScionForm;
const BookingForm = ({
  crmId,
  properties,
  setSelectedProperty,
  setFromStage,
  selectedProperty,
}) => {
  const handlePropertySelection = (propertyID) => {
    const [selectedProperty] = properties.filter(
      (ele) => ele.propertyID === propertyID
    );
    setSelectedProperty(selectedProperty);
  };
  return (
    <div className='booking-form'>
      <h1>Scion Booking Form</h1>
      <p className='input-label'>CRM Contact Id</p>
      <input
        className='input'
        type='text'
        placeholder='Enter CRM ID'
        value={crmId}
        disabled
      />
      <p className='input-label'>Property Name*</p>
      <select
        onChange={(e) => handlePropertySelection(e.target.value)}
        className='select-input'
        defaultValue='default'
      >
        <option disabled value='default'>
          Select one option
        </option>
        {properties?.map((property) => (
          <option key={property.propertyID} value={property.propertyID}>
            {property.displayName}
          </option>
        ))}
      </select>
      <div className='btn-parent'>
        <button
          disabled={!selectedProperty?.displayName}
          onClick={() => setFromStage(2)}
          className={
            !selectedProperty?.displayName
              ? 'form-submit-btn-disabled'
              : 'form-submit-btn'
          }
        >
          Preview
        </button>
      </div>
    </div>
  );
};
const Preview = ({
  selectedProperty,
  contactData,
  setFromStage,
  setError,
  crmId,
}) => {
  const [sendingMail, setSendingMail] = useState(false);
  const handleFormSubmit = async () => {
    const payload = {
      firstName: contactData?.First_Name,
      lastName: contactData?.Last_Name,
      email: contactData?.Email,
      contactNo: contactData?.Mobile,
      leadChannel: 'University Living',
      propertyId: selectedProperty?.propertyID,
      propertyName: selectedProperty?.displayName,
      scionMailers: selectedProperty?.scionMailers,
      crmId,
    };
    setSendingMail(true);
    const mailResponse = await PostScionBookingData(payload);
    setSendingMail(false);
    if (mailResponse.status !== 'success') {
      setError(mailResponse.data);
      return;
    }
    setFromStage(3);
  };
  return (
    <>
      <div className='preview-header'>
        New Email Lead for:{' '}
        <p className='preview-header-property-name'>
          {selectedProperty.displayName}
        </p>
      </div>
      <div className='preview-field-container'>
        <div className='preview-field'>First Name:</div>
        <div className='preview-field'>{contactData?.First_Name}</div>
      </div>
      <div className='preview-field-container'>
        <div className='preview-field'>Last Name:</div>
        <div className='preview-field'>{contactData?.Last_Name}</div>
      </div>
      <div className='preview-field-container'>
        <div className='preview-field'>Phone:</div>
        <div className='preview-field'>{contactData?.Mobile}</div>
      </div>
      <div className='preview-field-container'>
        <div className='preview-field'>Email:</div>
        <div className='preview-field'>{contactData?.Email}</div>
      </div>
      <div className='preview-field-container'>
        <div className='preview-field'>Lead Channel:</div>
        <div className='preview-field'>University Living</div>
      </div>
      <div className='preview-field-container'>
        <div className='preview-field'>Property ID:</div>
        <div className='preview-field'>{selectedProperty.propertyID}</div>
      </div>
      <div className='btn-preview'>
        <button
          disabled={sendingMail}
          onClick={handleFormSubmit}
          className='form-submit-btn'
        >
          {sendingMail ? 'Sending....' : 'Submit'}
        </button>
      </div>
    </>
  );
};
const Thankyou = ({ selectedProperty }) => {
  return (
    <>
      <h1>Email Sent Successfully</h1>
      <p className='thank-text'>Then email had been sent successfully to</p>
      <p className='thank-email'>{selectedProperty?.scionMailers}</p>
    </>
  );
};

