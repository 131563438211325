import { useEffect, useState } from "react";

import {
  GetContactData,
  ProvidersList,
  ProviderBookingFields,
  PostProviderBookingData,
} from "../../api/helper";
import "./styles/form.css";
import { capitalizeWords } from "../../utils/helperFunctions";

const ProviderForm = ({ params }) => {
  const [formStage, setFormStage] = useState(1);
  const [contactData, setContactData] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [providersList, setProvidersList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [formFields, setFormFields] = useState([]);
  const [formData, setFormData] = useState({
    crmId: params.crmId,
  });

  useEffect(() => {
    async function fetchData() {
      const contactResponse = await GetContactData(params.crmId);
      setLoading(false);
      if (contactResponse.status === "success") {
        setContactData(contactResponse.data);
      } else {
        setError(contactResponse.data);
      }
    }
    async function fetchProvidersList() {
      const providersList = await ProvidersList(params.crmId);
      setLoading(false);
      if (providersList.status === "success") {
        setProvidersList(providersList.data);
      } else {
        setError(providersList.data);
      }
    }
    fetchData();
    fetchProvidersList();
  }, [params]);

  const activeForm = {
    1: (
      <ProviderList
        providersList={providersList}
        selectedProvider={selectedProvider}
        setSelectedProvider={setSelectedProvider}
        setFormStage={setFormStage}
        setFormData={setFormData}
      />
    ),
    2: (
      <BookingForm
        crmId={params.crmId}
        setFormStage={setFormStage}
        formData={formData}
        setFormData={setFormData}
        params={params}
        selectedProvider={selectedProvider}
        formFields={formFields}
        setFormFields={setFormFields}
        setError={setError}
        contactData={contactData}
      />
    ),
    3: <Thankyou />,
  }[formStage];

  return (
    <>
      <div className="header">
        <img
          alt="logo"
          className="logo"
          src="https://cdn.universityliving.com/logo/newLogo.png"
        />
      </div>
      <div className="form-parent">
        <div className="main-form">
          {loading ? (
            <p className="loading">Loading....</p>
          ) : error ? (
            <p className="error">Error: {error}</p>
          ) : (
            activeForm
          )}
        </div>
      </div>
    </>
  );
};

export default ProviderForm;

const ProviderList = ({
  providersList,
  selectedProvider,
  setSelectedProvider,
  setFormStage,
  setFormData,
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProvider(value);
    setFormData((prevData) => ({
      ...prevData,
      provider: value,
    }));
  };
  return (
    <div className="provider-list">
      <h1>Select Your Provider</h1>
      <div className="form-row">
        <p className="input-label">Provider*</p>
        <select
          onChange={handleInputChange}
          className="select-input"
          defaultValue="default"
          name="city"
        >
          <option disabled value="default">
            Select an option
          </option>
          {providersList.map(({ providerLabel, providerValue }) => (
            <option key={providerLabel} value={providerValue}>
              {providerLabel}
            </option>
          ))}
        </select>
      </div>
      <div className="btn-parent">
        <button
          disabled={!selectedProvider}
          onClick={() => setFormStage(2)}
          className={
            selectedProvider ? "form-submit-btn" : "form-submit-btn-disabled"
          }
        >
          Next
        </button>
      </div>
    </div>
  );
};

const BookingForm = ({
  crmId,
  setFormStage,
  formData,
  setFormData,
  params,
  contactData,
  selectedProvider,
  formFields,
  setFormFields,
  setError,
}) => {
  useEffect(() => {
    async function fetchProviderBookingFields() {
      const providerFields = await ProviderBookingFields(selectedProvider);
      if (providerFields.status === "success") {
        setFormFields(providerFields?.data?.fields);
      } else {
        setError(providerFields?.data);
      }
    }

    fetchProviderBookingFields();
  }, [selectedProvider]);
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const finalValue = type === "number" ? Number(value) : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: finalValue,
    }));
  };

  // Check if required fields are complete
  const isFormComplete = formFields.every((field) =>
    field.required ? Boolean(formData[field.value]) : true
  );

  const handleFormSubmit = async () => {
    const payload = formData;
    const mailResponse = await PostProviderBookingData(payload);
    console.log(mailResponse);
    if (mailResponse.status !== "success") {
      setError(mailResponse.data);
      return;
    }
    setFormStage(3);
  };

  return (
    <div className="booking-form">
      <h1>{`${capitalizeWords(selectedProvider)} Booking Form`}</h1>

      {formFields.map((field) => {
        if (
          field.type === "text" ||
          field.type === "date" ||
          field.type === "number"
        ) {
          return (
            <div className="form-row" key={field.name}>
              <p className="input-label">
                {field.label}
                {field.required ? "*" : ""}
              </p>
              <input
                className="input"
                type={field.type}
                placeholder={field.placeholder || ""}
                onChange={handleInputChange}
                name={field.value}
                value={formData[field.value] || field.default || ""}
                disabled={field.disabled}
                min={field.min}
                max={field.max}
              />
            </div>
          );
        } else if (field.type === "select") {
          return (
            <div className="form-row" key={field.name}>
              <p className="input-label">
                {field.label}
                {field.required ? "*" : ""}
              </p>
              <select
                onChange={handleInputChange}
                className="select-input"
                name={field.value}
                value={formData[field.value] || "default"}
              >
                <option disabled value="default">
                  {field.placeholder || "Select an option"}
                </option>
                {field.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          );
        } else if (field.type === "radio") {
          return (
            <div className="form-row" key={field.name}>
              <p className="input-label">
                {field.label}
                {field.required ? "*" : ""}
              </p>
              <div className="radio-group">
                {field.options.map((option) => (
                  <label key={option.value} className="radio-label">
                    <input
                      type="radio"
                      name={field.value}
                      value={option.value}
                      checked={formData[field.value] === option.value}
                      onChange={handleInputChange}
                    />
                    {option.label}
                  </label>
                ))}
              </div>
            </div>
          );
        }
        return null;
      })}

      <div className="form-row">
        <p className="input-label">First Name*</p>
        <input
          className="input"
          type="text"
          name="First Name"
          value={contactData?.First_Name}
          disabled="true"
        />
      </div>

      <div className="form-row">
        <p className="input-label">Last Name*</p>
        <input
          className="input"
          type="text"
          name="Last Name"
          value={contactData?.Last_Name}
          disabled="true"
        />
      </div>

      <div className="form-row">
        <p className="input-label">Phone*</p>
        <input
          className="input"
          type="text"
          name="Phone"
          value={contactData?.Mobile}
          disabled="true"
        />
      </div>

      <div className="form-row">
        <p className="input-label">Email*</p>
        <input
          className="input"
          type="text"
          name="Email"
          value={contactData?.Email}
          disabled="true"
        />
      </div>

      <div className="btn-parent">
        <button
          disabled={!isFormComplete}
          onClick={handleFormSubmit}
          className={
            isFormComplete ? "form-submit-btn" : "form-submit-btn-disabled"
          }
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const Thankyou = () => {
  return (
    <>
      <h1>Lead Sent Successfully!</h1>
    </>
  );
};
