import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Index from "./pages/Index";
import NotFound from "./components/NotFound";
import GlobalContext from "./context/scape";
import ScionForm from "./pages/scion";
import JuneHomesForm from "./pages/juneHomes";
import ProviderForm from "./pages/provider";


function App() {
  /* maintain state in whole application */
  const [globalData, setGlobalData] = useState({
    error: null,
    status: "--",
    isEditable: false,
  });

  /* update only when error occurs */
  useEffect(() => {
    if (globalData.error) {
      setGlobalData((prev) => ({
        ...prev,
        isEditable: false,
      }));
    }
  }, [globalData.error]);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/provider-booking/:crmId"
          render={({ match }) => <ProviderForm params={match?.params} />}
        />

        <Route
          exact
          path="/scion/:crmId"
          render={({ match }) => <ScionForm params={match?.params} />}
        />
        
        <Route
          exact
          path="/june-homes/:crmId"
          render={({ match }) => <JuneHomesForm params={match?.params} />}
        />

        <Route
          exact
          path="/:provider/:bookingId"
          render={({ match }) => (
            <>
              <GlobalContext.Provider
                value={{
                  isProviderAval: true,
                  provider: match.params.provider,
                  bookingId: match.params.bookingId,
                  setGlobalData: setGlobalData,
                  ...globalData,
                }}
              >
                <Index />
              </GlobalContext.Provider>
            </>
          )}
        />

        <Route path="*">
          <NotFound
            title="404"
            description="The page you're looking for doesn't exist."
          />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
