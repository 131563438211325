const JUNE_HOMES_CITIES_CODE_MAP = {
    "Boston": "bos",
    "Los Angeles": "lax",
    "New York City": "nyc",
    "San Francisco": "sfo",
    "Washington DC": "was",
    "Chicago": "chi",
    "Jersey City": "ewr",
    "San Diego": "san",
    "Austin": "aus",
    "Dallas": "dfw"
  };
  export { JUNE_HOMES_CITIES_CODE_MAP };